import React from 'react'
import { connect } from 'react-redux'
import { Global, css } from '@emotion/core'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { getLanguage, getUser } from '../../store/data'
import localization from '../../constants/localization'
import LogoWithSpan from '../components/LogoWithSpan'
import bgImage from '../../assets/images/about-page-bg.jpg'
import { HeaderDefault, HeaderLoginUser } from '../components/Header'
import { isUserLoggedIn } from '../../utils/user'
import GlobalBg from '../components/GlobalBg'

export const Span = ({ diameter }) => (
  <span
    css={{
      height: diameter,
      width: diameter,
      backgroundColor: 'white',
      borderRadius: '50%',
      display: 'block',
      margin: '0.75rem auto',
    }}
  />
)
const About = ({ language, isLoggedIn }) => (
  <div>
    {isLoggedIn ? <HeaderLoginUser /> : <HeaderDefault />}

    <div className="pt-8">
      {/* global styles */}
      <GlobalBg bgImage={bgImage} />

      <Row className="flex justify-center my-6" noGutters={true}>
        <Col
          css={css`
            max-width: 36rem;
          `}
          sm={12}
          md={8}
        >
          <center className="text-white py-5 text-xl font-thin">
            {localization.get(language, 'aboutContent')}
          </center>
        </Col>
      </Row>

      <div className="mb-8">
        <Span diameter="12px" />
        <Span diameter="8px" />
        <Span diameter="5px" />
      </div>
    </div>
  </div>
)

const mapStateToProps = state => ({
  language: getLanguage(state),
  isLoggedIn: isUserLoggedIn(getUser(state)),
})

export default connect(mapStateToProps, {})(About)

import React from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import { Form } from 'react-final-form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { css } from '@emotion/core'

import { inviteMe } from '../../../actions/user'
import { getLanguage, getUser } from '../../../store/data'
import FormField from '../FormField'
import {
  required,
  validPhoneNumbuer,
  composeValidators,
} from '../FormField/validations'
import localization from '../../../constants/localization'
import MockupIphoneEn from '../../../assets/images/iphone-x-invite-en.webp'
import MockupIphoneFr from '../../../assets/images/iphone-x-invite-fr.webp'

const FormLayout = ({ children }) => (
  <div className="flex h-12 mb-12">{children}</div>
)

const InviteMeSection = ({ language, inviteMe: inviteMeAction }) => {
  const [message, setMessage] = useState('')
  const [messageColor, setMessageColor] = useState('')

  const onSubmit = values => {
    inviteMeAction({
      number: values.phone,
      language: language,
      callback: onInvite
    })
  }

  const onInvite = (message, success) => {
    setMessage(message)
    setMessageColor(success ? 'light' : 'danger')
    setTimeout(() => {
      setMessage('')
    }, 2000)
  }

  return (
    <div
      className="pt-8"
      css={css`
        background: linear-gradient(to right, #7fc5f9, #38a0f0);
      `}
    >
      <Row noGutters={true}>
        <Col
          className="pb-12 sm:pb-8 lg:pb-4"
          xs={{ offset: 1, span: 10 }}
          sm={{ offset: 2, span: 8 }}
          md={{ offset: 2, span: 4 }}
        >
          <div className="text-2xl lg:text-3xl py-12 text-white ">
            {localization.get(language, 'enterYourNumberSpan')}
          </div>
          <Form
            onSubmit={onSubmit}
            render={({
              handleSubmit,
              submitting,
              hasValidationErrors,
              form,
              ...props
            }) => {
              const customValidators = (...validators) => val =>
                composeValidators(validators, language, val)
              return (
                <form
                  onSubmit={async e => {
                    await handleSubmit(e)
                    await form.reset()
                  }}
                >
                  <FormLayout>
                    <FormField
                      className="-my-3 w-2/3"
                      placeholder={localization.get(
                        language,
                        'enterYourNumber'
                      )}
                      type="tel"
                      id="phone"
                      name="phone"
                      autoComplete="off"
                      validate={customValidators(required, validPhoneNumbuer)}
                      message={message}
                      messageColor={messageColor}
                    />
                    <Button
                      type="submit"
                      disabled={submitting || hasValidationErrors}
                      className={`leading-none border-none transition-all transition-250 hover:opacity-75 disabled:bg-gray-2 
                       ${'valid' in props && props.valid === true
                          ? ''
                          : 'cursor-not-allowed'
                        }
                       `}
                      variant="dark"
                      type="submit"
                    >
                      {localization.get(language, 'inviteMe')}
                    </Button>
                  </FormLayout>
                </form>
              )
            }}
          />
        </Col>
        <Col
          xs={{ offset: 3, span: 9 }}
          sm={{ offset: 1, span: 5 }}
          className="hidden md:flex flex-col-reverse"
        >
          <div className="-mt-4 md:w-4/5 lg:w-3/5 mt-8 sm:mt-0 ">
            <img src={language === 'fr' ? MockupIphoneFr : MockupIphoneEn} alt="iphone demo" />
          </div>
        </Col>
      </Row>
    </div >
  )
}
const mapStateToProps = state => ({
  language: getLanguage(state),
  user: getUser(state),
})

export default connect(mapStateToProps, { inviteMe })(InviteMeSection)

import React from 'react'
import { Field, useField } from 'react-final-form'

const Error = ({ name }) => {
  const {
    meta: { touched, error },
  } = useField(name, { subscription: { touched: true, error: true } })
  return touched && error ? (
    <span className="text-sm text-red-1 h-12">{error}</span>
  ) : null
}

const Message = ({ message, color = 'success' }) => {
  return (
    <span className={`text-sm text-${color} h-12`}>{message}</span>
  )
}

const Input = ({ className, input, ...props }) => (
  <input
    className={`w-full my-3 px-4 py-3 border text-sm text-black border-brown-1 rounded ${className}`}
    onChange={input.onChange}
    value={input.value}
    onBlur={input.onBlur}
    type={input.type}
    {...props}
  />
)

const FormField = ({
  label = '',
  name,
  showError = true,
  message,
  messageColor,
  className,
  ...fieldProps
}) => {
  return (
    <div className={`text-gray-10 ${className}`}>
      {label.length > 0 && <label>{label}</label>}
      <Field name={name} component={Input} {...fieldProps} />
      {showError && <Error name={name} />}
      {message && <Message message={message} color={messageColor} />}
    </div>
  )
}

export default FormField

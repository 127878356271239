import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { css } from '@emotion/core'

import localization from '../../../constants/localization'
import DownloadIcon from '../../../assets/images/download-icon.svg'
import CameraIcon from '../../../assets/images/camera-icon.svg'
import CashIcon from '../../../assets/images/cash-icon.svg'

const workIntruction = [
  {
    instrIcon: DownloadIcon,
    instrTitle: 'downloapTheApp',
    instrSub: 'downloapTheAppSub',
    instrNote: '',
  },
  {
    instrIcon: CameraIcon,
    instrTitle: 'takeReceiptPicture',
    instrSub: 'takeReceiptPictureSub',
    instrNote: '',
  },
  {
    instrIcon: CashIcon,
    instrTitle: 'getCashBack',
    instrSub: 'getCashBackSub',
    instrNote: '',
  },
]

const HowItWork = ({ isLogin = false, language }) => (
  <div
    id="HowItWorks"
    className={`bg-blue-6 ${isLogin ? 'mt-0' : '-mt-56'} lg:px-16`}
  >
    <center
      className={`text-2xl font-medium mb-1 ${
        isLogin ? 'pt-24 md:pt-20' : 'pt-56'
        }`}
    >
      {localization.get(language, 'howItWorks')}
    </center>
    <Row noGutters={true}
      css={css`
        max-width: 64rem;
      `}
      className={`mx-auto py-8 flex justify-center lg:justify-between md:justify-center`}
    >
      {workIntruction.map(detail => (
        <Col
          className="flex flex-col items-center mx-6 max-w-xs1 my-4"
          sm={{ offset: 1, span: 10 }}
          md={{ offset: 1, span: 5 }}
          lg={{ offset: 0, span: 3 }}
          key={detail.instrTitle}
        >
          <div className="flex items-end" style={{ height: '60px' }}>
            <img src={detail.instrIcon} />
          </div>
          <div className="text-xl font-medium my-2 -mx-12">
            {localization.get(language, detail.instrTitle)}
          </div>
          <center className="text-xl mb-2 leading-none font-hairline text-gray-4">
            {localization.get(language, detail.instrSub)}
          </center>
          <div className="text-xs px-2">
            {localization.get(language, detail.instrNote)}
          </div>
        </Col>
      ))}
    </Row>
  </div>
)

export default HowItWork

import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { connect } from 'react-redux'
import { Link } from 'gatsby'

import localization from '../../../constants/localization'
import { changeLanguage } from '../../../actions/user'
import { getLanguage, getUser } from '../../../store/data'
import { SignupButton, CustomButton } from '../Button'

const textItems = language => [
  {
    name: localization.get(language, 'findDeals'),
    to: '/app/deals',
  },
  {
    name: localization.get(language, 'howItWorks'),
    to: `/app/#HowItWorks`,
  },
  // {
  //   name: localization.get(language, 'blog'),
  //   to: '/app/blog',
  // },
]

// individual text item
const TextItem = ({ name = '', to = '/' }) => (
  <Link
    className=" text-white text-base no-underline md:mr-8 transition-all transition-250 hover:opacity-75"
    to={to}
  >
    {name}
  </Link>
)

// header
const Header = ({ user, language, changeLanguage: changeLanguageAction }) => {
  return (
    <Navbar collapseOnSelect expand="md" className="py-6">
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        className="alert-primary"
      />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        className="justify-content-end"
      >
        <Nav className="items-center h-56 flex flex-col sm:flex-row sm:h-auto justify-around">
          {textItems(language).map(textItem => (
            <TextItem key={textItem.to} name={textItem.name} to={textItem.to} />
          ))}

          {/* login && signup; hidden when user login*/}

          <Link
            to="/app/login"
            className="text-blue-1 bg-white text-sm h-8 flex items-center px-6 rounded-full no-underline md:mr-4 transition-all transition-250 hover:opacity-75"
          >
            {localization.get(language, 'login')}
          </Link>
          <SignupButton language={language} />

          {/* language */}
          <CustomButton
            className="bg-transparent md:ml-4 border-2 border-white"
            onClick={() => {
              changeLanguageAction(language === 'en' ? 'fr' : 'en')
            }}
            content={language === 'en' ? 'FR' : 'EN'}
          />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

const mapStateToProps = state => ({
  language: getLanguage(state),
  user: getUser(state),
})

export default connect(
  mapStateToProps,
  { changeLanguage }
)(Header)

import React from 'react'
import { css } from '@emotion/core'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Col'

import DownloadLinks from '../components/DownloadLinks'
import LogoWithSpan from '../components/LogoWithSpan'
import { Span } from '../About/index'
import iphoneXDemoEn from '../../assets/images/iphone-x-demo-en.webp'
import iphoneXDemoFr from '../../assets/images/iphone-x-demo-fr.webp'

const PhoneDemo = ({ language }) => (
  <Row className="flex flex-wrap justify-center items-center md:justify-end">
    <Col
      className="flex justify-center"
      xs={{ span: 12 }}
      md={{ span: 3 }}
      lg={{ span: 4 }}
    >
      <div id='maitre-widget-MF25d56fe545' />
    </Col>
    <Col
      className="flex flex-col justify-even items-center sm:pt-24 z-10"
      xs={{ span: 12 }}
      md={{ span: 6 }}
      lg={{ span: 4 }}
    >
      <LogoWithSpan className="mt-8" />
      <DownloadLinks />
      <div className="flex flex-col mb-4 hidden sm:block ">
        <Span diameter="12px" />
        <Span diameter="8px" />
        <Span diameter="5px" />
      </div>
    </Col>

    <Col
      xs={{ span: 12 }}
      md={{ span: 3 }}
      lg={{ span: 4 }}
      className="flex justify-center pt-8 -mb-16 md:pr-12"
    >
      <img
        src={language === 'fr' ? iphoneXDemoFr : iphoneXDemoEn}
        css={css`
          max-width: 15.6rem;
          max-height: 31.25rem;
        `}
        alt="demo"
      />
    </Col>
  </Row>
)

export default PhoneDemo
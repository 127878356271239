import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { connect } from 'react-redux'
import { css } from '@emotion/core'
import { Link } from 'gatsby'

import localization from '../../../constants/localization'
import { getUser, getLanguage } from '../../../store/data'
import myAccount from '../../../assets/images/my-account-icon.svg'
import alerts from '../../../assets/images/alerts.svg'
import logout from '../../../assets/images/logout.svg'
import arrowDown from '../../../assets/images/dropdown-menu-arrow.svg'
import accountAvatar from '../../../assets/images/avatar-icon.png'
import { logoutUser } from '../../../actions/user'
import { cashExchange } from '../../../utils/currencyExchange'

const dropdownMenuStyle = css`
  box-shadow: 0px 0 1rem 0 #cbd5e0;
  left: -40px;
  :after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0.75rem 0.75rem;
    border-color: transparent transparent white transparent;
    right: 15%;
    top: -1.5rem;
  }
  @media only screen and (max-width: 769px) {
    right: 10;
  }
`

const AccountToggle = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className="w-40 flex flex-row justify-around content-center">
      <img
        className="w-8 h-8 overflow-hidden"
        src={props.user.profilePictureUrl || accountAvatar}
        alt="alt"
      />
      <span className="mt-1">{props.user.firstName}</span>
      <a
        href=""
        onClick={e => {
          e.preventDefault()
          props.onClick(e)
        }}
        className="mt-1"
      >
        <button>
          <img src={arrowDown} alt="arrow" />
        </button>
      </a>
    </div>
  )
})

const AccountMenu = React.forwardRef((props, ref) => (
  <div ref={ref} style={props.style} className={props.className}>
    <ul>{props.children}</ul>
  </div>
))

const textItems = language => [
  {
    menuIcon: myAccount,
    menuName: localization.get(language, 'myAccount'),
    to: '/app/myaccount',
  },
  {
    menuIcon: alerts,
    menuName: localization.get(language, 'alerts'),
    to: '/app/alerts',
  },
]

const TextItem = ({ icon = '', name = '', to = '/' }) => (
  <Link
    className="flex pl-6 mt-4 h-8 items-center hover:text-gray-1 text-base opacity-50 no-underline transition-all transition-250 hover:opacity-100 hover:bg-gray-100"
    to={to}
    activeClassName="opacity-100"
  >
    <img src={icon} alt={name} />
    <div className="ml-4">{name}</div>
  </Link>
)

const AccountDropdown = ({
  user,
  language,
  updateProfielPic: updateProfielPicAction,
  logoutUser: logoutUserAction,
}) => {
  const clickLogout = e => {
    e.preventDefault()
    logoutUserAction()
  }

  return (
    <div className="flex items-center ">
      <Dropdown className="flex">
        <Dropdown.Toggle
          className="text-gray"
          user={user.data.user}
          as={AccountToggle}
          id="dropdown-custom-components"
        />
        <div>
          <Dropdown.Menu
            className="mt-5 sm:mt-10 mr-12 w-56 rounded-lg border-gray-300 pb-8 absolute"
            css={dropdownMenuStyle}
            as={AccountMenu}
          >
            <Dropdown.Item
              eventKey="1"
              className="flex flex-col content-center mt-6"
            >
              <center className="text-sm text-gray-2">Balance</center>
              <center className="text-green-1 text-2xl">
                {cashExchange(language, user.data.user.balance)}
              </center>
            </Dropdown.Item>
            <Dropdown.Divider
              css={css`
                margin: 1.5rem auto;
                width: 80%;
                border-width: thin;
              `}
            />
            {textItems(language).map(textItem => (
              <TextItem
                key={textItem.to}
                name={textItem.menuName}
                to={textItem.to}
                icon={textItem.menuIcon}
              ></TextItem>
            ))}

            <Link
              className="flex pl-6 mt-4 h-8 items-center hover:text-gray-1 text-base opacity-50 no-underline transition-all transition-250 hover:opacity-100 hover:bg-gray-100"
              to="/app/logout"
              onClick={clickLogout}
            >
              <img src={logout} alt="logout icon" />
              <div className="ml-4">{localization.get(language, 'logout')}</div>
            </Link>
          </Dropdown.Menu>
        </div>
      </Dropdown>
    </div>
  )
}

const mapStateToProps = state => ({
  language: getLanguage(state),
  user: getUser(state),
})

export default connect(
  mapStateToProps,
  { logoutUser }
)(AccountDropdown)

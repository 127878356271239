import React from 'react'
import { connect } from 'react-redux'
import { Global, css } from '@emotion/core'

import { getLanguage, getUser } from '../../store/data'

import bgImage from '../../assets/images/signup-page-bg.webp'
import HowItWorks from '../components/HowItWork'
import Slideshow from '../components/Slideshow'
import Testimonials from '../components/Testimonials'
import GreatSavings from '../components/GreatSavings'
import InviteMeSection from '../components/InviteMeSection'
import { HeaderDefault, HeaderLoginUser } from '../components/Header'
import { isUserLoggedIn } from '../../utils/user'
import PhoneDemo from './PhoneDemo'

const Landing = ({ language, isLoggedIn }) => (
  <div>
    {/* global styles */}
    <Global
      styles={css`
        body {
          background-image: url(${bgImage});
          background-position: bottom;
          background-color: #1f272e;
          background-repeat: no-repeat;
          height: 70vh;
          min-height: 570px;
          @media only screen and (max-width: 1280px) {
            background-position: bottom;
            background-size: contain 100%;
          }

          @media only screen and (max-width: 768px) {
            background-position: bottom;
            height: 100vh;
            min-height: 1250px;
          }
        }

        #mtr-optin-form {
          max-width: 80% !important;
          width: 80% !important;

          @media only screen and (max-width: 1280px) {
            max-width: 100% !important;
            width: 100% !important;
          }

          @media only screen and (min-width: 768px) and (max-width: 1000px) {
            zoom: 0.6;
          }
        }
      `}
    />

    {isLoggedIn ? <HeaderLoginUser /> : <HeaderDefault />}

    <PhoneDemo language={language} />

    <HowItWorks isLogin={true} language={language} />

    <Slideshow language={language} />

    <GreatSavings language={language} />

    <Testimonials c language={language} />

    <InviteMeSection language={language} />
  </div>
)

const mapStateToProps = state => ({
  language: getLanguage(state),
  isLoggedIn: isUserLoggedIn(getUser(state)),
})

export default connect(mapStateToProps)(Landing)

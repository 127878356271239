import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { connect } from 'react-redux'
import { css } from '@emotion/core'
import { Link } from 'gatsby'

import localization from '../../../constants/localization'
import { getLanguage, getUser, getDeals } from '../../../store/data'
import dealLogo from '../../../assets/images/deal-icon.svg'
import clippedLogo from '../../../assets/images/clipped-icon.svg'
import eclipsaLogo from '../../../assets/images/eclipsa-logo-color.svg'
import AccountDropdown from '../AccountDropdown'
import './custom-nav.css'

//language setting should be read from the user'state
const textItems = (language, offerNumber) => [
  {
    name: localization.get(language, 'deals'),
    to: '/app/deals',
    logo: dealLogo,
  },
  {
    name: localization.get(language, 'clipped'),
    to: '/app/clipdeals',
    logo: clippedLogo,
    offerNumber: offerNumber,
  },
]

// individual text item
const TextItem = ({ name = '', to = '/', src = '', offerNumber = '' }) => (
  <Link
    id="nav-link"
    className=" nav-link flex w-full justify-around text-gray-1 text-base md:mr-2 ml-2 transition-all transition-250  hover:opacity-100 "
    to={to}
    activeClassName="active"
    style={{ textDecoration: 'none' }}
  >
    <img src={src} alt="name" className="mr-2 w-1/7" />
    {name}
    {to === '/app/clipdeals' ? (
      <div className="flex self-center justify-center items-center block ml-2 h-6 w-6  border rounded-full border-black">
        {offerNumber}
      </div>
    ) : (
        ''
      )}
  </Link>
)

// header for login user
const HeaderLoginUser = ({ language, user, deals }) => {
  const clippedOffers = user.data.user.clippedOffers
  const filteredOffers = deals.data.filter(e => clippedOffers.includes(e._id))
  const offerNumber = filteredOffers.length
  return (
    <Navbar
      collapseOnSelect
      expand="md"
      className="border-bottom bg-white"
      css={css`
        box-shadow: 0px 1px 4px -2px grey;
        min-height: 5.7rem;
      `}
    >
      {/* this is for the responsive mobile part  */}
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        className="alert-primary"
      />

      {/* eclipsa-logo */}
      <Nav.Item
        css={css`
          width: 70%;
          display: flex;
          justify-content: flex-start;

          @media only screen and (max-width: 640px) and (orientation: portrait) {
            width: 64%;
            display: inherit;
            justify-content: unset;
          }

          @media only screen and (max-width: 1000px) and (orientation: landscape) {
            width: 58%;
            display: inherit;
            justify-content: unset;
          }
        `}
      >
        <Link key="eclipsaLogo" to="/app">
          <center>
            <img src={eclipsaLogo} alt="eclipsaLogo" />
          </center>
        </Link>
      </Nav.Item>

      <Navbar.Collapse
        id="responsive-navbar-nav"
        className="justify-content-between"
      >
        <Nav
          variant="tabs"
          defaultActiveKey={location.pathname}
          className="custom-nav-bg flex justify-around items-center border-none"
        >
          {/* active key will be the name */}

          {textItems(language, offerNumber).map(textItem => (
            <Nav.Item key={textItem.to} className="mx-4">
              <TextItem
                eventKey={textItem.to}
                name={textItem.name}
                to={textItem.to}
                src={textItem.logo}
                offerNumber={offerNumber}
              />
            </Nav.Item>
          ))}

          {/* profile toggledown menu */}
          <AccountDropdown />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

const mapStateToProps = state => ({
  language: getLanguage(state),
  user: getUser(state),
  deals: getDeals(state),
})

export default connect(
  mapStateToProps,
  {}
)(HeaderLoginUser)

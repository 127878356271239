import React, { useState, useEffect } from 'react'
import { css } from '@emotion/core'

import SlideshowImage1En from '../../../assets/images/slideshow-image-en-1.webp'
import SlideshowImage2En from '../../../assets/images/slideshow-image-en-2.webp'
import SlideshowImage3En from '../../../assets/images/slideshow-image-en-3.webp'

import SlideshowImage1Fr from '../../../assets/images/slideshow-image-fr-1.webp'
import SlideshowImage2Fr from '../../../assets/images/slideshow-image-fr-2.webp'
import SlideshowImage3Fr from '../../../assets/images/slideshow-image-fr-3.webp'

//  TODO: add the rest of the slider images once they are availble

const slidesEn = [SlideshowImage1En, SlideshowImage2En, SlideshowImage3En]
const slidesFr = [SlideshowImage1Fr, SlideshowImage2Fr, SlideshowImage3Fr]

// Slider navigation dot component
const SliderNav = ({ selected, handleClick, index }) => {
  const opacity = selected === index ? 'opacity-100' : 'opacity-40'
  const size = selected === index ? 'scale(1)' : 'scale(0.6, 0.6)'

  return (
    <li
      className={`block my-5 sm:my-2 mx-auto rounded-full bg-blue-5 cursor-pointer h-3 w-3 ${opacity}`}
      css={css`
        transform: ${size};
        transition-duration: 0.4s;
        transition-property: all;
      `}
      tabIndex="0"
      onKeyPress={event => event.key === 'Enter' && handleClick(index)}
      onClick={() => handleClick(index)}
    />
  )
}

// Slide image component
const SliderImage = ({ selected, image, index }) => {
  const opacity = selected === index ? 'opacity-100' : 'opacity-0'

  return (
    <li
      className={`absolute ${opacity}`}
      css={{ transition: '0.4s ease-in-out all' }}
    >
      <img src={image} alt={`slideshow image ${index}`} />
    </li>
  )
}

// Slider component
const Slider = ({ language }) => {
  // Set first slide as the default slide
  const [selected, setSelected] = useState(0)
  const handleClick = x => setSelected(x)
  const slides = language === 'fr' ? slidesFr : slidesEn

  useEffect(() => {
    const rotateSlideshow = setInterval(() => {
      setSelected(selected !== slides.length - 1 ? selected + 1 : 0)
    }, 2000)

    // Clean up function to clear interval on unmount
    return function cleanup() {
      clearInterval(rotateSlideshow)
    }
  }, [selected])

  return (
    <div className="flex flex-col items-center sm:flex-row sm:flex-row-reverse justify-center items-center w-full">
      <ul className="relative" css={{ height: '500px', width: '250px' }}>
        {slides.map((image, index) => (
          <SliderImage
            key={image}
            index={index}
            selected={selected}
            image={image}
          />
        ))}
      </ul>
      <ul className="flex w-1/4 my-2 sm:w-4 sm:mr-6  sm:flex-col self-center ">
        {slides.map((image, index) => (
          <SliderNav
            key={`slider-nav-item-${index}`}
            index={index}
            selected={selected}
            handleClick={handleClick}
          />
        ))}
      </ul>
    </div>
  )
}

export default Slider

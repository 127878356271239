import React from 'react'
import { css } from '@emotion/core'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Slider from '../Slider'

import localization from '../../../constants/localization'
import BrandsIcon from '../../../assets/images/slideshow-icon-brands.svg'
import CartIcon from '../../../assets/images/slideshow-icon-cart.svg'
import CashIcon from '../../../assets/images/slideshow-icon-cash.svg'
import eTransferIcon from '../../../assets/images/e-transfer-icon.png'

import eTransferIconFr from '../../../assets/images/e-transfer-icon-fr.png'

const slideshowItems = [
  {
    icon: BrandsIcon,
    title: 'favBrands',
    desc: 'earnCBOnYourFavBrands',
  },
  {
    icon: CartIcon,
    title: 'buyAnywhere',
    desc: 'purchaseFromYourFavStores',
  },
  {
    icon: CashIcon,
    title: 'getPaid',
    desc: 'onceYouEarnTwentyDollars',
  },
]

const Slideshow = ({ language }) => (
  <div className="flex flex-col justify-center items-center m-0 py-12 bg-white">
    <h2 className="mb-12 p-0 pt-2 text-2xl font-medium">
      {localization.get(language, 'funFreeWay')}
    </h2>

    <Row noGutters={true}
      className="flex flex-wrap justify-center items-center"
      css={{ maxWidth: '740px' }}
    >
      <Col className="px-0" xs={12} md={6}>
        <Slider language={language} />
      </Col>
      <Col className="px-16" xs={12} md={6}>
        {slideshowItems.map(({ icon, title, desc }) => (
          <div
            key={title}
            className="items-end mt-6 mb-4"
            css={css`
              display: grid;
              grid:
                'icon title' 1fr
                'desc desc' auto
                / 10% 90%;
            `}
          >
            <img src={icon} className="pb-1 w-100" css={{ gridArea: 'icon' }} />
            <strong className="pl-4" css={{ gridArea: 'title' }}>
              {localization.get(language, title)}
            </strong>
            <p className="py-3 w-100" css={{ gridArea: 'desc' }}>
              {localization.get(language, desc)}
            </p>
          </div>
        ))}
        <div>
          <img
            className="w-1/3"
            css={css`
              max-width: 7rem;
            `}
            src={language === 'en' ? eTransferIcon : eTransferIconFr}
          />
        </div>
      </Col>
    </Row>
  </div>
)

export default Slideshow

import React from 'react'
import { connect } from 'react-redux'
import { css } from '@emotion/core'
import Card from 'react-bootstrap/Card'

import currencyExchange from '../../utils/currencyExchange'
import { getLanguage } from '../../store/data'
import clipIcon from '../../assets/images/clipped-icon.svg'
import clipBlueIcon from '../../assets/images/clipped-blue-icon.svg'
import { RoundButton } from '../components/Button'

const ItemCard = ({
  deal,
  language,
  onClick,
  _clippedOnClick,
  isClipped = '',
  className = '',
  isGreatSave = '',
  ...props
}) => {
  return (
    <Card
      css={css`
        // width: 16.25rem;
        min-height: 19rem;
        height: 100%;
        min-width: 153px;
        @media only screen and (max-width: 768px) {
          width: 100%;
          height: 100%;
        }
      `}
      className={`flex flex-col items-center justify-between rounded-lg overflow-hidden pt-4 ${className} ${isGreatSave &&
        'px-4 py-6 shadow-xl'}`}
    >
      <div className="pic-wrapper flex flex-grow flex-col justify-center items-center ">
        <button
          className={`flex justify-center items-center outline-none ${isGreatSave &&
            'pointer-events-none'}`}
          onClick={onClick}
        >
          <div className="flex items-center justify-center max-h-3/5">
            <img
              className={`${isGreatSave ? 'w-4/5 md:w-full' : 'w-3/5 h-1/2'}`}
              src={deal.products ? deal.products[0].productMediaUrl : deal.img}
            />
          </div>
        </button>
      </div>

      <div className="flex-grow-0 w-full px-4 my-4 text-sm">
        <div className="text-gray-9">{deal.title}</div>
        <div className="flex justify-between align-center flex-wrap">
          <div className="text-green-1 font-medium self-center flex-grow">
            {currencyExchange(language, deal.cashBack)}
          </div>
          {/* a white place for greatDealsBanner */}
          <div
            className="flex-grow text-transparent mx-4"
            css={css`
              content: '';
            `}
          >
            {' "" '}
          </div>
          <RoundButton
            className={`z-1000  ${isGreatSave && '-mx-4 pointer-events-none'}`}
            onClick={_clippedOnClick}
          >
            <img
              css={css`
                position: absolute;
                width: 1rem;
                width: 60%;
                left: 0.3rem;
                top: 0.25rem;
              `}
              src={isClipped ? clipBlueIcon : clipIcon}
              alt="clipped"
            />
          </RoundButton>
        </div>
      </div>
    </Card>
  )
}

const mapStateToProps = state => ({
  language: getLanguage(state),
})

export default connect(
  mapStateToProps,
  {}
)(ItemCard)

const currencyExchange = (language, cash) =>
  language === 'en'
    ? `$ ${cash.toFixed(2)} Cash Back`
    : `${cash.toFixed(2).replace('.', ',')} $ de remise`

export const cashExchange = (language, cash) =>
  language === 'en'
    ? `$ ${cash.toFixed(2)}`
    : `${cash.toFixed(2).replace('.', ',')} $`

export default currencyExchange

import React, { useState, useEffect } from 'react'
import { css } from '@emotion/core'
import Carousel from 'react-bootstrap/Carousel'

import localization from '../../../constants/localization'
import testimonialJessica from '../../../assets/images/testimonial-jessica.webp'
import testimonialSarah from '../../../assets/images/testimonial-sarah.webp'
import testimonialAshely from '../../../assets/images/testimonial-ashley.webp'
import testimonialBrianna from '../../../assets/images/testimonial-brianna.webp'
import testimonialJennifer from '../../../assets/images/testimonial-jennifer.webp'

/* TODO: Change images and names */
const testimonials = [
  {
    image: testimonialSarah,
    name: 'Sarah',
    content: 'testimonialSarah',
  },
  {
    image: testimonialJessica,
    name: 'Jessica',
    content: 'testimonialJessica',
  },
  {
    image: testimonialAshely,
    name: 'Ashley',
    content: 'testimonialAshley',
  },
  {
    image: testimonialBrianna,
    name: 'Brianna',
    content: 'testimonialBrianna',
  },
  {
    image: testimonialJennifer,
    name: 'Jennifer',
    content: 'testimonialJennifer',
  },
]

// Testimonial slider navigation component
/* TODO: set translateX and slide nav items as user clicks, to give a carousel effect */
const TestimonialNav = ({ selected, handleClick, clickNext, index }) => {
  const size = selected === index ? 'scale(1)' : 'scale(0.3, 0.3)'
  const opacity = selected === index ? 'opacity-100' : 'opacity-40'
  const display = selected === index ? 'flex' : 'none'
  const bgEffect =
    selected === index ? 'bg-white shadow' : 'bg-gray-8 shadow-none'

  return (
    <li
      className={`m-1 rounded-full cursor-pointer h-8 w-8  ${bgEffect}`}
      css={css`
        position: relative;
        transform: ${size};
        transition: 0.4s all;
        &:before {
          content: '→';
          display: ${display};
          position: absolute;
          justify-content: center;
          align-items: center;
          width: 2rem;
          height: 2rem;
          font-size: 1.25rem;
          text-align: center;
        }
      `}
      tabIndex="0"
      onKeyPress={event => event.key === 'Enter' && handleClick(index)}
      onClick={() => {
        if (index === selected) {
          clickNext()
        } else {
          handleClick(index)
        }
      }}
    />
  )
}

// Testimonial slide content component
const TestimonialContent = ({ card, index, selected, language }) => {
  const { image, name, content } = card
  const opacity = selected === index ? 'opacity-100' : 'opacity-0'
  return (
    <li
      className={`absolute text-center ${opacity}`}
      css={{ transition: '0.4s ease-in-out all' }}
    >
      <img
        className="mx-auto w-24 rounded-full"
        src={image}
        alt={`testimonial image ${index}`}
      />
      <p className="my-4 text-sm">{name}</p>
      <p className="my-6">{localization.get(language, content)}</p>
    </li>
  )
}

// Testimonial slider component
const Testimonials = ({ language }) => {
  const [selected, setSelected] = useState(0)
  const handleClick = x => setSelected(x) //select the current x

  //select the next
  const clickNext = () =>
    setSelected(selected !== testimonials.length - 1 ? selected + 1 : 0)

  useEffect(() => {
    const rotateCarousel = setInterval(() => {
      setSelected(selected !== testimonials.length - 1 ? selected + 1 : 0)
    }, 7000)

    // Clean up function to clear interval on unmount
    return function cleanup() {
      clearInterval(rotateCarousel)
    }
  }, [selected])

  return (
    <div className="flex flex-column justify-center items-center m-0 pt-56 pb-12 bg-blue-6">
      <ul
        className="relative flex flex-column justify-start items-center w-50"
        css={{ height: '260px', maxWidth: '740px' }}
      >
        {testimonials.map((card, index) => (
          <TestimonialContent
            className="flex flex-col justify-start"
            key={card.content}
            index={index}
            card={card}
            selected={selected}
            language={language}
          />
        ))}
      </ul>
      {/* hide the slider dot */}
      {/* <div className="mt-10 sm:mt-0">
        <ul className="flex justify-center items-center ">
          {testimonials.map(({ content }, index) => (
            <TestimonialNav
              key={`slider-nav-item-${content}`}
              index={index}
              selected={2}
              clickNext={clickNext}
              handleClick={handleClick}
            />
          ))}
        </ul>
      </div> */}
    </div>
  )
}

export default Testimonials

import localization from '../../../constants/localization'

export const required = (value, language) =>
  value ? undefined : localization.get(language, 'required')

export const mustBeNumber = (value, language) =>
  isNaN(value) ? localization.get(language, 'mustBeNumber') : undefined

export const minValue = min => (value, language) => {
  return isNaN(value) || value >= min
    ? undefined
    : `${localization.get(language, 'minValue')} ${min}`
}

export const minLength = minLen => (value, language) =>
  value && value.trim().length >= minLen
    ? undefined
    : ` ${localization.get(language, 'minLength')} ${minLen}`

export const composeValidators = (validators, language, value) =>
  validators.reduce(
    (error, validator) => error || validator(value, language),
    undefined
  )

export const validEmail = (email, language) => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email) ? undefined : localization.get(language, 'invalidEmail')
}

//These formats include 1234567890, 123-456-7890, 123.456.7890, 123 456 7890, (123) 456 7890, and all related combinations.
export const validPhoneNumbuer = (tel, language) => {
  const re = /^(1[.-])?(\(\d{3}\)[.-]|(\d{3}[.-]?)|(\d{3}[ ]?)|(\(\d{3}\)[ ]?)|(\+1-\(\d{3}\)[.-]?)|(\+1\d{3})){2}\d{4,5}$/
  return re.test(tel)
    ? undefined
    : localization.get(language, 'invalidPhoneNumber')
}

//validate Canadian postol code
// Disallows: Leading Z,W or to contain D, F, I, O, Q or U
export const validPostalCode = (postalCode, language) => {
  const re = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
  return re.test(postalCode)
    ? undefined
    : localization.get(language, 'invalidPostalCodeCa')
}

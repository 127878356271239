import React from 'react'
import { Global, css } from '@emotion/core'

export default ({ bgImage }) => (
  <Global
    styles={css`
      body {
        background-image: url(${bgImage});
        background-position: bottom;
        background-color: #1f272e;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
        min-height: 800px;
        @media only screen and (max-width: 1280px) {
          background-size: contain 100%;
        }
      }
    `}
  />
)

import React, { useState } from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import { css } from '@emotion/core'

import localization from '../../../constants/localization'
import ItemCard from '../../Deals/DealCard'

import greatDealImage1 from '../../../assets/images/great-deal-banana.png'
import greatDealImage2 from '../../../assets/images/great-deal-avocado.png'
import greatDealImage3 from '../../../assets/images/great-deal-bread.png'
import { getLanguage } from '../../../store/data'

// Great savings component
const greatDeals = language => [
  {
    title: localization.get(language, 'banana'),
    cashBack: 0.5,
    showAsBanner: true,
    _id: 'greatDeals1',
    img: greatDealImage1,
  },
  {
    title: localization.get(language, 'avocado'),
    cashBack: 0.5,
    showAsBanner: true,
    _id: 'greatDeals2',
    img: greatDealImage2,
  },
  {
    title: localization.get(language, 'bread'),
    cashBack: 0.5,
    showAsBanner: true,
    _id: 'greatDeals3',
    img: greatDealImage3,
  },
]

const GreatSavings = ({ language }) => {
  const [scrolled, setScrolled] = useState(false)
  const scrolledToComponent = () => setScrolled(true)
  return (
    <div
      className="flex flex-col justify-even items-center bg-white overflow-visible py-8 px-8 bg-blue-6"
      css={css`
        height: 17rem;

        @media only screen and (max-width: 640px) and (orientation: portrait) {
          height: 44rem;
        }

        @media only screen and (max-width: 640px) and (orientation: landscape) {
          height: 32rem;
        }
      `}
    >
      <center className="text-2xl font-medium">
        {localization.get(language, 'earnCbOnProductsYouLove')}
      </center>
      <div className="absolute w-100"
        css={css`
          margin-top: 7rem !important;
          margin-bottom: 6rem !important;
       `}
      >
        <ScrollAnimation
          animateIn="fadeIn"
          duration={0}
          afterAnimatedIn={scrolledToComponent}
          initiallyVisible={true}
          animateOnce={true}
        >
          <div
            className={`relative flex justify-center align-center my-0 mx-auto w-100 ${
              scrolled ? 'opacity-1' : 'opacity-0'
              }`}
            css={css`
              transform: ${scrolled ? 'translate(0, 0)' : 'translate(0, 60px)'};
              transition: 0.4s all;
              -ms-box-orient: horizontal;
              flex-wrap: wrap;
            `}
          >
            {/* Product Cards */}

            <div
              css={css`
                top: 10px;
                left: 0;
                max-height: 300px;
                width: 250px;

                @media only screen and (max-width: 640px) and (orientation: portrait) {
                  z-index: 1000;
                  left: 0;
                  width: 60%;
                  max-width: 220px;
                  height: 250px;
                }

                @media only screen and (max-width: 1000px) and (orientation: landscape) {
                  z-index: 1000;
                  left: 0;
                  width: 60%;
                  max-width: 220px;
                  height: 250px;
                }
              `}
            >
              <ItemCard
                isGreatSave
                className="border-none"
                deal={greatDeals(language)[0]}
              />
            </div>

            <div
              css={css`
                z-index: 999;
                top: 20px;
                height: 300px;
                width: 250px;
                transform: translate(12px,20px);

                @media only screen and (max-width: 640px) and (orientation: portrait) {
                  top: 0;
                  left: 0;
                  width: 60%;
                  max-width: 220px;
                  height: 250px;
                  transform: translate(0,0);
                }

                @media only screen and (max-width: 1000px) and (orientation: landscape) {
                  top: 0;
                  left: 0;
                  width: 60%;
                  max-width: 220px;
                  height: 250px;
                  transform: translate(0,0);
                }
              `}
            >
              <ItemCard
                isGreatSave
                className="border-none"
                deal={greatDeals(language)[1]}
              />
            </div>

            <div
              css={css`
                top: 10px;
                height: 300px;
                width: 250px;
                transform: translate(24px,0);

                @media only screen and (max-width: 640px) and (orientation: portrait) {
                  top: 0;
                  left: 0;
                  width: 60%;
                  max-width: 220px;
                  height: 250px;
                  transform: translate(0,0);
                }

                @media only screen and (max-width: 1000px) and (orientation: landscape) {
                  top: 0;
                  left: 0;
                  width: 60%;
                  max-width: 220px;
                  height: 250px;
                  transform: translate(0,0);
                }
              `}
            >
              <ItemCard
                isGreatSave
                className="border-none"
                deal={greatDeals(language)[2]}
              />
            </div>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  )
}

export default GreatSavings
